.contact-form-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 5%;
    width: 100%;
}

.contact-form-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1050px;
    width: 100%;
    padding: 40px;
    border-radius: 12px;
    gap: 80px;
    /* 🔥 Gap ko 80px kar diya hai for more space */
    height: auto;
    background-color: #fff;
}

.contact-form-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.contact-form-image img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 12px;
}


.contact-form-content {
    flex: 1.2;
    text-align: left;
}

.contact-form-title {
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: bold;
}

.contact-form-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #000000;
}

.contact-form input,
.contact-form select {
    width: 100%;
    padding: 10px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.contact-form-inline {
    display: flex;
    gap: 15px;
}

.contact-form-inline label {
    flex: 1;
}

.contact-form-checkboxes input {
    margin: 0;
    width: 16px;
    height: 16px;
}

.contact-form-submit-btn {
    background: linear-gradient(to right, #0a9d96, #1d2224);
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
}

.contact-form-submit-btn:hover {
    background-color: #0056b3;
}

.contact-form-footer {
    text-align: left;
    margin-top: 15px;
    font-size: 12px;
    color: #4a6a92;
}

.contact-form-footer a {
    color: #007bff;
    text-decoration: none;
}

.contact-form-footer a:hover {
    text-decoration: underline;
}

.asterisk {
    color: red;
    font-weight: bold;
    margin-left: 2px;
}

.error-input {
    border: 1px solid red !important;
    outline: none !important;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}

.contact-form-checkboxes-vertical label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    font-size: 14px;
}

.contact-form-checkboxes-vertical input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin: 0;
}

/* ========================= */
/* ✅✅✅ Fully Responsive ✅✅✅ */
/* ========================= */

/* Tablet View - 768px se kam width */
@media (max-width: 992px) {
    .contact-form-container {
        flex-direction: column;
        text-align: left;
        padding: 30px;
    }

    .contact-form-image img {
        max-width: 100%;
        height: auto;
    }

    .contact-form-content {
        text-align: left;
    }

    .contact-form-inline {
        flex-direction: column;
        gap: 10px;
    }
}

/* Mobile View - 576px se kam width */
@media (max-width: 576px) {
    .contact-form-section {
        padding: 20px 3%;
    }

    .contact-form-title {
        font-size: 24px;
    }

    .contact-form-description {
        font-size: 14px;
    }

    .contact-form-container {
        padding: 20px;
        gap: 20px;
    }

    .contact-form-image img {
        height: 250px;
    }

    .contact-form-submit-btn {
        font-size: 14px;
        padding: 10px;
    }

    .contact-form-inline {
        flex-direction: column;
        gap: 8px;
    }

    .contact-form-footer {
        font-size: 10px;
    }
}

/* Smallest Devices - 400px se kam width */
@media (max-width: 400px) {
    .contact-form-title {
        font-size: 20px;
    }

    .contact-form-description {
        font-size: 13px;
    }

    .contact-form-submit-btn {
        font-size: 13px;
        padding: 8px;
    }
}