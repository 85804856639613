body,
html {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}


.hero-section {
    padding: 120px 0;
    background: linear-gradient(135deg, #ffffff, #fefefe, #e9ecef);
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

/* 🌀 Canvas Background Animation */
.hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
}

/* 🎯 Hero Content */
.hero-section h3 {
    font-size: 2.6rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
    background: linear-gradient(to right, #0a9d96, #1d2224);
    -webkit-background-clip: text;
    color: transparent;
}


/* 🎯 Highlighted Words */
.hero-section .highlight {
    color: #007bff;
    font-weight: 700;
    text-shadow: 2px 2px 10px rgba(0, 123, 255, 0.3);
}


.hero-section #underborders {
    font-size: 2.1rem;
    color: #000000;
    font-weight: 700;
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 10px 20px;
    line-height: 1.2;
    white-space: nowrap;
    /* ✅ Yeh line mobile ke liye overwrite hogi */
}

.hero-section #underborders::before,
.hero-section #underborders::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    border: 4px solid #000000;
}

.hero-section #underborders::before {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
    transform: translate(-2px, -2px);
}

.hero-section #underborders::after {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
    transform: translate(5px, 5px);
}


.hero-section .btn {
    transition: width 0.5s ease, height 0.5s ease, box-shadow 0.5s ease;
}

.hero-section .btn:hover {
    box-shadow: 10px 10px 10px grey;
}




/* ✅ Responsive Styling */
@media (max-width: 768px) {
    .hero-section #underborders {
        font-size: 1rem;
        padding: 8px 16px;
        white-space: normal;
        /* ✅ Text wrap hoga */
        width: 100%;
        /* ✅ Full width le lega */
        text-align: center;
        /* ✅ Text center me rahega */
    }

    .hero-section #underborders::before,
    .hero-section #underborders::after {
        width: 40px;
        height: 40px;
        border-width: 3px;
    }
}

@media (max-width: 480px) {
    .hero-section #underborders {
        font-size: 1.5rem;
        padding: 6px 12px;
        white-space: normal;
        /* ✅ Text wrap hoga */
        width: 100%;
        text-align: center;
    }

    .hero-section #underborders::before,
    .hero-section #underborders::after {
        width: 30px;
        height: 30px;
        border-width: 2px;
    }
}




/* 📝 Description */
.hero-section p {
    font-size: 1.4rem;
    color: #000000;
    max-width: 900px;
    margin: 0 auto 25px;
    line-height: 1.8;
    position: relative;
    z-index: 1;
}


.img-fluid {
    width: 450px;
    height: 450px;
    background-image: url('../Images/auth-key-background.png');
    background-size: cover;
}


/* 🎯 Call-to-Action Button */
.hero-section .btn-primary {
    background: linear-gradient(to right, #0a9d96, #1d2224);
    border: none;
    padding: 15px 35px;
    font-size: 1.4rem;
    font-weight: 700;
    border-radius: 10px;
    transition: all 0.3s ease-in-out, transform 0.2s ease-in-out;
    box-shadow: 0px 5px 20px rgba(0, 123, 255, 0.3);
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    position: relative;
    z-index: 1;
    overflow: hidden;
}




@media (max-width: 480px) {
    .hero-section {
        padding: 50px 15px;
        min-height: auto;
    }

    .hero-section h1 {
        font-size: 2rem;
        letter-spacing: 1px;
    }

    .hero-section h2 {
        font-size: 1.5rem;
    }

    .hero-section p {
        font-size: 1rem;
        line-height: 1.6;
    }

    .hero-section .btn-primary {
        font-size: 1rem;
        padding: 10px 24px;
        width: 100%;
        text-align: center;
    }
}

/* 📱 Foldable Phones (Max 375px) */
@media (max-width: 375px) {
    .hero-section h1 {
        font-size: 1.8rem;
    }

    .hero-section h2 {
        font-size: 1.4rem;
    }

    .hero-section p {
        font-size: 0.9rem;
    }

    .hero-section .btn-primary {
        font-size: 0.9rem;
        padding: 8px 20px;
    }
}

/* 🖥️ Medium Screens (Max 768px) */
@media (max-width: 768px) {
    .hero-section {
        padding: 70px 20px;
    }

    .hero-section h1 {
        font-size: 2.5rem;
    }

    .hero-section h2 {
        font-size: 2rem;
    }

    .hero-section p {
        font-size: 1.1rem;
    }

    .hero-section .btn-primary {
        font-size: 1.2rem;
        padding: 14px 32px;
    }

    .img-fluid {
        width: 300px;
        height: 300px;
    }
}

/* 🖥️ Large Screens (Min 1200px) */
@media (min-width: 1200px) {
    .hero-section {
        padding: 150px 0;
    }

    .hero-section h1 {
        font-size: 4rem;
    }

    .hero-section h2 {
        font-size: 2.5rem;
    }

    .hero-section p {
        font-size: 1.5rem;
    }

    .hero-section .btn-primary {
        font-size: 1.4rem;
        padding: 18px 40px;
    }
}

/* 🖥️ Ultra-Wide Screens (Min 1600px) */
@media (min-width: 1600px) {
    .hero-section {
        padding: 180px 0;
    }

    .hero-section h1 {
        font-size: 4.5rem;
    }

    .hero-section h2 {
        font-size: 3rem;
    }

    .hero-section p {
        font-size: 1.8rem;
    }

    .hero-section .btn-primary {
        font-size: 1.6rem;
        padding: 20px 50px;
    }
}