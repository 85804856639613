.windowCodeIntegration {
    width: 100%;
    min-height: 500px;
    border: 1px solid transparent;
    box-sizing: border-box;
}

.IntegrationHeading {
    margin-top: 50px;
}

.code-integration-sleek {
    background: #0d0d0d;
    border-radius: 10px;
    padding: 10px;
    color: #e6e6e6;
    max-width: 70%;
    min-height: 280px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    margin-left: auto;
    margin-right: auto;

}

/* Tab Header */
.code-tab-sleek-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #1a1a1a;
    padding-bottom: 10px;
    margin-bottom: 20px;
    overflow-x: auto;
    scrollbar-width: none;
}

.code-tab-sleek-header::-webkit-scrollbar {
    display: none;
}

/* Tabs */
.code-tab-sleek {
    display: flex;
    align-items: center;
    color: #f0f0f0;
    font-size: 14px;
    padding: 10px 20px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s;
    border-radius: 5px;
    margin-right: 10px;
    position: relative;
    background: transparent;
}

.code-tab-sleek:hover {
    color: #fff;
}

.code-tab-icon {
    font-size: 18px;
    margin-right: 8px;
    transition: color 0.3s ease;
}

/* Active Tab */
.code-tab-active {
    color: #000;
    background: linear-gradient(to right, #00b3aa, #00e6cc, #33ffd6);
    box-shadow: 0 2px 7px rgba(0, 179, 170, 0.6);
    /* Glowing effect */
    /* Aqua Background */
    border-radius: 20px;
    /* Rounded Corners */
    /* box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5); */
    transition: background 0.3s ease, color 0.3s ease;
    margin-right: 0px;
}

.code-tab-active+.code-tab-sleek::before,
.code-tab-sleek+.code-tab-active::before {
    display: none;
}

/* Divider */
.code-tab-sleek::before {
    content: "|";
    color: #fff;
    /* White Color */
    margin-left: 10px;
    margin-right: 10px;
    font-weight: lighter;
    opacity: 0.8;
}

.code-tab-sleek:first-child::before {
    content: "";
}

/* Go Icon Styling */
.go-icon {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 14px;
    transition: color 0.3s ease;
}

/* Hover Effects */
.code-tab-sleek:hover .go-icon {
    color: #fff;
}

.code-tab-sleek:hover .code-tab-icon {
    color: #fff;
}

/* Code Content */
.code-content-sleek {
    border-radius: 8px;
    padding: 10px;
    color: #d4d4d4;
    overflow-x: auto;
    font-family: "Courier New", monospace;
    font-size: 14px;
    border: 1px solid grey;
    transition: background 0.3s ease;
}


.code-content-sleek pre {
    border: 1px solid grey;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
}


.copy-button {
    display: inline-flex;
    align-items: center;
    background: #333;
    color: #e6e6e6;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

.copy-button:hover {
    background: #444;
}

.copy-button svg {
    margin-right: 5px;
}

.copied-tag {
    display: inline-block;
    background: #4caf50;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 6px;
    font-size: 12px;
    animation: fadeOut 2s forwards;
    vertical-align: middle;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* Responsive for devices with width 900px or less */
@media (max-width: 900px) {
    .code-integration-sleek {
        max-width: 90%;
        /* Width chhoti screens pe adjust ho jayegi */
        padding: 15px;
    }

    .code-tab-sleek-header {
        display: flex;
        overflow-x: auto;
        /* Horizontal scroll ko enable karenge */
        flex-wrap: nowrap;
        /* Tabs ko ek hi line me rakhenge */
    }

    .code-tab-sleek {
        font-size: 12px;
        /* Tab text ka size chhota ho jayega */
        padding: 8px 15px;
        margin-right: 10px;
        white-space: nowrap;
        /* Prevent tab text from wrapping */
        flex-shrink: 0;
        /* Prevent tabs from shrinking */
    }

    .code-content-sleek {
        padding: 15px;
        font-size: 12px;
        /* Code content ka font size thoda chhota */
    }

    .copy-button {
        padding: 4px 8px;
        /* Copy button ko chhota karega */
    }
}

/* Responsive for mobile devices (600px or less) */
@media (max-width: 600px) {
    .windowCodeIntegration {
        margin-top: 100px;
        /* Top margin ko thoda reduce kiya */
        padding: 10px;
        /* Padding thodi kam ki */
    }

    .code-integration-sleek {
        max-width: 100%;
        /* Code box poora width le lega */
        padding: 10px;
    }

    .code-tab-sleek-header {
        display: flex;
        overflow-x: auto;
        /* Horizontal scroll ko enable karega */
        flex-wrap: nowrap;
        /* Tabs ko ek hi line me rakhenge */
    }

    .code-tab-sleek {
        font-size: 12px;
        padding: 6px 12px;
        margin-right: 5px;
        text-align: center;
        white-space: nowrap;
        /* Prevent tab text from wrapping */
        flex-shrink: 0;
        /* Prevent tabs from shrinking */
    }

    .code-content-sleek {
        padding: 10px;
        font-size: 12px;
    }

    .copy-button {
        padding: 4px 8px;
        /* Button ko adjust karega */
    }

    .go-icon {
        font-size: 12px;
        /* Icon ka size chhota hoga */
    }
}