.client-names {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background: #ffffff;
    /* Background color */
    min-height: 200px;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.client-slider {
    display: flex;
    animation: slide 20s linear infinite;
    /* Speed control */
    position: absolute;
    white-space: nowrap;
}

.client {
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: transform 0.3s ease-in-out;
}

.client img {
    height: 40px;
    margin-right: 15px;
    filter: grayscale(100%);
    /* Default grey effect */
    transition: all 0.3s ease-in-out;
}

.client p {
    color: grey;
    /* Default grey text color */
    transition: color 0.3s ease-in-out;
}

.client:hover {
    transform: scale(1.1);
    /* Slightly enlarge on hover */
}

.client:hover img {
    height: 50px;
    filter: grayscale(0%);
    /* Remove grayscale on hover */
}

.client:hover p {
    color: black;
    /* Change text color to black on hover */
}

/* Keyframe for sliding animation */
@keyframes slide {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-50%);
        /* Moves the logos left */
    }

    100% {
        transform: translateX(0);
        /* Resets to initial position */
    }
}