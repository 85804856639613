.faq-section {
    width: 100%;
    padding: 40px 20px;
    background: transparent;
}

.faq-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Left Side - Image */
.faq-image {
    flex: 1;
    max-width: 500px;
}

.faq-image img {
    width: 100%;
    height: auto;
    border-radius: 12px;
}

/* Right Side - FAQs */
.faq-content {
    flex: 1;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 10px;
}

.faq-title {
    font-size: 1.9rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #222;
    text-align: left;
}

.faq-item {
    background: #f9f9f9;
    padding: 12px 18px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ddd;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #000000;
    margin-bottom: 10px;
}

.faq-item.open {
    background: #00946320;
    border: 1px solid #009463;
}

/* Question section styling */
.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question-text {
    font-size: 1rem;
    font-weight: normal;
}

.faq-icon {
    font-size: 1.5rem;
    font-weight: bold;
    color: #009463;
    margin-left: 10px;
}

/* Answer section styling */
.faq-answer {
    font-size: 0.9rem;
    color: #000000;
    margin-top: 10px;
    display: block;
    transition: all 0.3s ease;
    padding: 10px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-weight: 500;
}

/* Scrollbar Styling */
.faq-content::-webkit-scrollbar {
    width: 5px;
}

.faq-content::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

.faq-content::-webkit-scrollbar-thumb:hover {
    background: #888;
}

/* Responsive Design */
@media (max-width: 900px) {
    .faq-container {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

    .faq-title {
        text-align: center;
    }

    .faq-image {
        max-width: 100%;
    }

    .faq-item {
        padding: 10px;
    }
}