/* 🔥 Base Navbar */
.navbar-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 30px;
    background: rgba(255, 255, 255, 0.132);
    /* Light glass effect */
    border-bottom: 1px solid rgba(102, 102, 102, 0.2);
    backdrop-filter: blur(10px);
    /* Glass effect */
    -webkit-backdrop-filter: blur(10px);
    transition: all 0.3s ease-in-out;
    z-index: 1000;
}

/* 🔥 Logo */
.navbar-custom .logo {
    background-image: url('../Images/whitelogofornav.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 250px;
    height: 56px;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.navbar-custom .logo:hover {
    transform: scale(1.05);
}

/* 🔥 Navbar Links */
.navbar-custom .nav-link {
    color: #001f3f;
    font-weight: 600;
    font-size: 16px;
    margin-right: 20px;
    text-transform: capitalize;
    transition: color 0.3s ease-in-out;
    position: relative;
    /* To position the underline */
}

/* Add underline on hover using ::after */
.navbar-custom .nav-link::after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    /* Adjust thickness of underline */
    background-color: #00462f;
    /* Underline color */
    transition: width 0.3s ease-in-out;
    /* Smooth animation */
    position: absolute;
    bottom: -5px;
    /* Adjust space between text and underline */
    left: 0;
}

/* On hover, the underline expands */
.navbar-custom .nav-link:hover {
    color: #005236;
}

.navbar-custom .nav-link:hover::after {
    width: 100%;
}

/* ✅ Sidebar Toggle Button */
.sidebar-toggle {
    position: fixed;
    top: 15px;
    left: 15px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1050;
}

/* ✅ Mobile Sidebar */
.mobile-sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100vh;
    background: #ffffff;
    border-right: 2px solid #e0e0e0;
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease-in-out;
    padding-top: 50px;
    z-index: 1040;
}

.mobile-sidebar.open {
    left: 0;
}

/* ❌ Close Button */
.mobile-sidebar .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

/* ✅ Sidebar Links */
.sidebar-nav {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}

.sidebar-nav .nav-link {
    font-size: 16px;
    color: #001f3f;
    font-weight: 600;
    padding: 10px;
    text-decoration: none;
}

.sidebar-nav .nav-link:hover {
    color: #003926;
}

/* ✅ Custom Dropdown */
.custom-dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
}

/* 🔥 Dropdown Toggle */
.custom-dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 16px;
    color: #003121;
    border: none;
    background: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

/* 🔥 Dropdown Arrow */
.custom-dropdown .arrow-icon {
    transition: transform 0.3s ease-in-out;
}

.custom-dropdown.show .arrow-icon {
    transform: rotate(180deg);
}

.custom-dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.3s ease-in-out;
    min-width: 220px;
    z-index: 1000;
}

.custom-dropdown:hover .dropdown-menu,
.custom-dropdown.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

/* ✅ Dropdown Items */
.custom-dropdown .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 20px;
    color: #009463;
    font-size: 15px;
}

.custom-dropdown .dropdown-menu .dropdown-item:hover {
    background: linear-gradient(to right, #0a9d96, #1d2224);
    color: #ffffff;
}

/* 🔥 Responsive Fixes */
@media (max-width: 768px) {
    .navbar-custom {
        display: none;
        /* ❌ Hide Default Navbar */
    }

    .sidebar-toggle {
        display: block;
    }
}

.sidebar-logo {
    width: 140px;
    /* Adjust size as needed */
    height: 60px;
    /* Adjust size as needed */
    background-image: url("../Images/whitelogofornav.png");
    /* Change to your logo path */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px auto;
    /* Centers it horizontally */
}