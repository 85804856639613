.enterprise-solutions {
    width: 100%;
    max-width: 100%;
    padding: 50px 0;
    text-align: center;
    color: #333;
    position: relative;
    overflow: hidden;
    background: #f7f7f8;
}

/* 🔷 Floating Background Animation */
.enterprise-solutions::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    animation: floatingBg 12s infinite linear;
    z-index: 0;
}

@keyframes floatingBg {
    0% {
        transform: translateY(-20px) translateX(-20px);
    }

    50% {
        transform: translateY(20px) translateX(20px);
    }

    100% {
        transform: translateY(-20px) translateX(-20px);
    }
}

/* 🎯 Title Styling */
.section-titlle {
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
    color: black;
}

.solution-card {
    background: #ffffff;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 25px;
    text-align: left;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    height: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
}

/* 🔷 Card Head - Height zyada kiya */
.card-head {
    flex: 2;
    /* Height zyada kar diya head ka */
    padding: 25px;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(12px);
    border-bottom: 2px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* 🔷 Icon - Top left perfectly aligned */
.icon-container {
    font-size: 1rem;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

/* 🔷 Title and Description */
.card-title {
    display: inline-block;
    text-align: left;
    padding-left: 0;
    margin-left: 0;
}

.card-title h1 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
    color: black;
}

.card-description {
    font-size: 0.95rem;
    color: #000000;
    margin-top: 10px;
    line-height: 1.4;
}

/* 🔷 Card Body - Height kam kiya aur grey background perfect fit */
.card-body {
    background-color: #f5f5f5;
    padding: 15px;
    width: 100%;
    height: 80px;
    /* ⬅️ Kam height body ke liye */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0 0 12px 12px;
}

/* 🔷 Button - Visible & Styled */
.btn-learn-more {
    font-size: 1rem;
    font-weight: bold;
    color: #005551;
    text-decoration: none;
    border-bottom: 2px solid #005551;
    transition: color 0.3s ease, border-bottom 0.3s ease, transform 0.2s ease;
}

.btn-learn-more:hover {
    color: #0056b3;
    border-bottom: 2px solid #0056b3;
    transform: translateY(-3px);
}


/* Ensuring equal height cards */
.row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    /* Ensures all cards are the same height */
}

.col-md-4 {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
    .section-title {
        font-size: 1.8rem;
    }

    .solution-title {
        font-size: 1.1rem;
    }

    .card-head {
        padding-bottom: 10px;
    }

    .card-body {
        padding: 10px 0;
    }
}