.testimonial-section {
    text-align: center;
    padding: 50px;
    background: #f7f7f8;
    color: #000000;
}

.testimonial-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 50px;
}

.highlight {
    color: #007bff;
}

.testimonial-container {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.testimonial-list {
    display: flex;
    gap: 20px;
    will-change: transform;
}

.testimonial-box {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    padding: 20px 30px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    width: 350px;
    flex-shrink: 0;
    transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
}

.testimonial-box:hover {
    transform: scale(1.05);
    background: rgba(240, 240, 240, 0.3);
}

.testimonial-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 15px;
    border: 3px solid #007bff;
}

.testimonial-content {
    text-align: left;
}

.testimonial-text {
    font-size: 16px;
    font-style: italic;
}

.testimonial-name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
}

/* 📌 Responsive Fixes */
@media (max-width: 1024px) {
    .testimonial-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .testimonial-box {
        width: 300px;
        flex-direction: column;
        text-align: center;
        padding: 20px;
    }

    .testimonial-img {
        margin: 0 auto 15px;
    }
}

@media (max-width: 768px) {
    .testimonial-heading {
        font-size: 2rem;
    }

    .testimonial-box {
        width: 100%;
        max-width: 350px;
    }
}

@media (max-width: 480px) {
    .testimonial-box {
        width: 90%;
        padding: 15px;
    }

    .testimonial-text {
        font-size: 14px;
    }

    .testimonial-name {
        font-size: 16px;
    }
}