.uniquePro-card {
    width: 250px;
    height: 250px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(240, 248, 255, 0.534);
    backdrop-filter: blur(25px);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
    border: 2px solid rgba(238, 250, 255, 0.664);
    transition: all 0.4s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.uniquePro-enterprise-products {
    background-color: #ffffff;
    /* Background color */
    background-image: radial-gradient(#d7d7d746 10%, transparent 10%);
    background-size: 40px 40px; /* Dot ke size aur gap ke liye */
}



/* Hiding the image and showing the content on hover */
.uniquePro-card-head img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.4s ease-in-out;
    border-radius: 10px;
}

.uniquePro-card-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: white;
    text-align: center;
    transition: opacity 0.4s ease-in-out;
}

.uniquePro-card:hover .uniquePro-card-head img {
    opacity: 0;
}

.uniquePro-card:hover .uniquePro-card-body {
    opacity: 1;
}

/* Icons and text styling */
.uniquePro-card-title {
    font-size: 0.8rem;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.3s ease-in-out;
}

.uniquePro-card-text {
    width: 200px;
    font-size: 0.7rem;
    color: rgb(0, 0, 0);
    text-align: center;
    margin-top: 12px;
    line-height: 1.3;
    font-weight: 400;
    padding: 15px;
}

/* Icon transitions */
.uniquePro-card-icon {
    font-size: 2rem;
    color: black;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.uniquePro-heading {
    font-size: 34px;
    color: black;
}

.uniquePro-card:hover {
    transform: scale(1.1) translateY(-10px);
    /* Card will grow and lift */
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.15);
    /* Enhance the shadow */
}

/* 🌟 Responsive Adjustments */
@media (max-width: 1024px) {
    .uniquePro-card {
        width: 220px;
        height: 220px;
        padding: 30px;
    }

    .uniquePro-card-icon {
        font-size: 1.5rem;
    }

    .uniquePro-card-title {
        font-size: 0.9rem;
    }

    .uniquePro-card-text {
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .uniquePro-card {
        width: 200px;
        height: 200px;
        padding: 25px;
    }

    .uniquePro-card-icon {
        font-size: 0.9rem;
    }

    .uniquePro-card-title {
        font-size: 0.7rem;
    }

    .uniquePro-card-text {
        font-size: 0.6rem;
    }
}

@media (max-width: 480px) {
    .uniquePro-card {
        width: 180px;
        height: 180px;
        padding: 20px;
    }

    .uniquePro-card-icon {
        font-size: 1.2rem;
    }

    .uniquePro-card-title {
        font-size: 0.6rem;
    }

    .uniquePro-card-text {
        font-size: 0.5rem;
    }
}