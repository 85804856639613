.business-safety-container {
    margin-top: 100px;
    text-align: center;
    padding: 40px 20px;
    background-image: linear-gradient(180deg, #fcefdd 22.65%, #fff 70.64%);
}

.heading {
    font-size: 2rem;
    font-weight: bold;
    color: #000000;
    margin-bottom: 30px;
}

.certificates {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
}

.certificate-card {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 450px;
    /* Adjusted width for better layout */
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    /* Add space between cards on smaller screens */
}

.certificate-icon {
    width: 80px;
    /* Increased size of image */
    height: 80px;
    /* Increased size of image */
    margin-right: 20px;
    /* Space between image and text */
}

.certificate-text h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2c3e50;
}

.certificate-text p {
    font-size: 1rem;
    color: #7f8c8d;
    margin-top: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .certificate-card {
        width: 100%;
        /* Full width on smaller screens */
        justify-content: center;
        text-align: center;
        /* Center text on smaller screens */
    }

    .certificate-icon {
        width: 60px;
        /* Slightly smaller image on smaller screens */
        height: 60px;
        /* Slightly smaller image on smaller screens */
        margin-right: 0;
        /* Remove margin */
        margin-bottom: 15px;
        /* Add space below image */
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 1.5rem;
        /* Smaller heading on very small screens */
        margin-bottom: 20px;
    }

    .certificate-text h3 {
        font-size: 1rem;
        /* Smaller heading in the cards */
    }

    .certificate-text p {
        font-size: 0.9rem;
        /* Smaller text in the cards */
    }
}