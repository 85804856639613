/* Section Styles */
.uniqueBlog-section {
    padding: 50px;
    background: #f7f7f8;
    color: #333;
}

.section-title {
    font-size: 2rem;
    font-weight: bold;
    color: #000000;
    text-align: center;
    letter-spacing: 1px;
    position: relative;
}


/* Blog Card Styles */
.uniqueBlog-card {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    background-color: #f9f9f9;
    /* Light background color for the card */
    height: 250px;
    /* Reduced height */
    margin-bottom: 20px;
    /* Reduced spacing between cards */
    width: 100%;
    /* Ensures cards take full width of their container */
}

/* Hover effect for cards */
.uniqueBlog-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
}

.uniqueBlog-card-img {
    width: 100%;
    height: 250px;
    /* Reduced the height of the image */
    object-fit: cover;
    transition: transform 0.3s ease;
    filter: brightness(0.8);
    /* Slight darkening for more contrast */
}

.uniqueBlog-card:hover .uniqueBlog-card-img {
    transform: scale(1.1);
    filter: brightness(1);
    /* Reset to normal brightness on hover */
}

.uniqueBlog-card-content {
    padding: 15px;
    text-align: center;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: transform 0.3s ease;
    transform: translateY(100%);
}

.uniqueBlog-card:hover .uniqueBlog-card-content {
    transform: translateY(0);
    /* Show content on hover */
}

.uniqueBlog-card-title {
    font-size: 1.1rem;
    /* Adjusted title size */
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
    text-transform: uppercase;
}

.uniqueBlog-card-text {
    font-size: 0.9rem;
    /* Adjusted text size */
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 10px;
    line-height: 1.5;
}

.uniqueBlog-card-link {
    font-size: 1rem;
    font-weight: bold;
    color: #00aaff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.uniqueBlog-card-link:hover {
    color: #007acc;
}

/* 🌟 Responsive Adjustments */
@media (max-width: 1024px) {
    .uniqueBlog-card {
        width: 100%;
        margin-bottom: 20px;
    }

    .uniqueBlog-card-title {
        font-size: 1.1rem;
    }

    .uniqueBlog-card-text {
        font-size: 0.9rem;
    }

    .uniqueBlog-card-link {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px) {
    .uniqueBlog-card {
        width: 100%;
        height: auto;
        margin-bottom: 25px;
    }

    .uniqueBlog-card-title {
        font-size: 1rem;
    }

    .uniqueBlog-card-text {
        font-size: 0.9rem;
    }

    .uniqueBlog-card-link {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .uniqueBlog-card-title {
        font-size: 0.9rem;
    }

    .uniqueBlog-card-text {
        font-size: 0.85rem;
    }

    .uniqueBlog-card-link {
        font-size: 0.9rem;
    }
}