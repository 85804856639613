.progress-section {
    padding: 60px 0;
    background: #f7f7f8;
}

.journey-title {
    color: #0a9d96;
    font-weight: 600;
}

.journey-heading {
    font-size: 2rem;
    font-weight: 700;
    color: #121212;
    margin-bottom: 20px;
}

.highlight {
    color: #0a9d96;
}

.request-btn {
    background: linear-gradient(to right, #0a9d96, #1d2224);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 20px;
}

.timeline {
    position: relative;
    padding-left: 40px;
}

.timeline::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    width: 3px;
    height: 100%;
    background: #c2ccff;
}

.progress-line {
    position: absolute;
    left: 10px;
    top: 0;
    width: 3px;
    height: 0%;
    background: linear-gradient(180deg, #8100a8, #ff00ff);
    transition: height 0.3s ease-out;
}

.timeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    opacity: 0.3;
    transition: opacity 0.5s ease-in-out;
}

.timeline-item.active {
    opacity: 1;
}

.step-badge {
    background: #0a9d96;
    color: white;
    padding: 6px 20px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 11px;
}

.timeline-content {
    margin-left: 20px;
}

.step-title {
    color: #0a9d96;
    font-weight: 600;
}

.step-subtitle {
    font-weight: 700;
}

.timeline-content ul {
    padding-left: 20px;
    list-style: disc;
    color: #555;
}


@media (max-width: 1024px) {
    .progress-section {
        padding: 50px 5%;
    }

    .journey-heading {
        font-size: 1.8rem;
    }
}

@media (max-width: 768px) {
    .progress-section {
        padding: 40px 5%;
    }

    .journey-heading {
        font-size: 1.5rem;
        text-align: center;
    }

    .timeline {
        padding-left: 30px;
    }

    .timeline::before,
    .progress-line {
        left: 8px;
        width: 3px;
    }

    .timeline-item {
        flex-wrap: wrap;
    }

    .timeline-content {
        margin-left: 15px;
    }
}

@media (max-width: 480px) {
    .progress-section {
        padding: 30px 5%;
    }

    .journey-heading {
        font-size: 1.3rem;
    }

    .timeline {
        padding-left: 20px;
    }

    .timeline::before,
    .progress-line {
        left: 5px;
        width: 2px;
    }

    .timeline-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .timeline-content {
        margin-left: 10px;
    }

    .step-badge {
        font-size: 10px;
        padding: 5px 12px;
    }
}