.footer {
    background: #000000;
    color: #ffffff;
    padding: 60px 30px;
    font-family: "Inter", sans-serif;
    text-align: center;
    border-top: 2px solid #222;
    box-shadow: 0px -4px 20px rgba(255, 255, 255, 0.05);
    transition: all 0.3s ease-in-out;
}

.footer:hover {
    background: #111;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
    transition: all 0.3s ease-in-out;
}

/* Left Section - Logo & Description */
.footer-logo-section {
    flex: 1;
    text-align: left;
    max-width: 350px;
    transition: transform 0.3s ease-in-out;
}

.footer-logo-section:hover {
    transform: translateY(-5px);
}

.footer-logo {
    width: 180px;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.footer-logo:hover {
    transform: scale(1.1);
}

.footer-logo-section p {
    font-size: 15px;
    color: #ffffff;
    line-height: 1.6;
}

/* Center Section - Quick Links */
.footer-links {
    flex: 1;
    text-align: left;
    min-width: 100px;
}

.footer-links h3,
.footer-contact h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #ffffff;
    letter-spacing: 0.5px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links ul li {
    margin-bottom: 12px;
    transition: transform 0.3s ease-in-out;
}


.footer-links ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    transition: color 0.3s ease-in-out;
}

.footer-links ul li a:hover {
    color: #00ffcc;
    font-weight: 600;
}

/* Right Section - Contact Info */
.footer-contact {
    flex: 1;
    text-align: left;
    min-width: 200px;
}

.footer-contact p {
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 8px;
}

/* Social Media Section */
.footer-socials {
    margin-top: 30px;
    text-align: center;
}

.footer-socials h3 {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 25px;
}

.social-icon {
    font-size: 30px;
    color: #ffffff;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    cursor: pointer;
}

.social-icon:hover {
    color: #00ffcc;
    transform: scale(1.2);
}

/* Copyright Text */
.footer-text {
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    margin-top: 40px;
    letter-spacing: 0.5px;
}

/* Responsive */
@media (max-width: 900px) {
    .footer-container {
        flex-direction: column;
        text-align: center;
    }

    .footer-logo-section,
    .footer-links,
    .footer-contact {
        text-align: center;
    }

    .footer-logo {
        width: 160px;
        margin-bottom: 10px;
    }

    .footer-links ul li {
        margin-bottom: 10px;
    }

    .footer-links ul li a {
        font-size: 14px;
    }

    .footer-socials {
        margin-top: 20px;
    }

    .footer-socials h3 {
        font-size: 16px;
    }

    .social-icons {
        gap: 20px;
    }

    .footer-text {
        font-size: 14px;
        margin-top: 20px;
    }
}

/* Responsive for mobile devices (smaller than 600px) */
@media (max-width: 600px) {
    .footer-container {
        padding: 20px;
    }

    .footer-logo-section p,
    .footer-links ul li a,
    .footer-contact p {
        font-size: 14px;
    }

    .footer-logo-section {
        max-width: 300px;
    }

    .footer-links ul {
        padding: 0;
    }

    .footer-socials {
        margin-top: 15px;
    }

    .social-icons {
        gap: 15px;
    }

    .footer-text {
        font-size: 12px;
        margin-top: 15px;
    }
}